dmx.Component('query-manager', {

    initialData: {
        data: {}
    },

    attributes: {},

    methods: {
        set: function(key, value) {
            this.setQueryParam(key, value);
        },

        remove: function(key) {
            this.setQueryParam(key);
        }
    },

    render: function(node) {
        this.update();
    },

    update: function() {
        var query = '';

        if (window.location.search) {
            query = window.location.search.substr(1);
        } else if (window.location.hash.indexOf('?')) {
            query = window.location.hash.substr(window.location.hash.indexOf('?') + 1);
            if (query.indexOf('#')) {
                query = query.substr(0, query.indexOf('#'));
            }
        }

        this.set('data', query.split('&').reduce(function(data, part) {
            var p = part.split('=');
            if (p[0]) {
                data[decodeURIComponent(p[0])] = decodeURIComponent(p[1] || '');
            }
            return data;
        }, {}));
    },

    setQueryParam: function(key, value) {
        var data = dmx.clone(this.data.data);

        if (value == null) {
            delete data[key];
        } else {
            data[key] = value;
        }

        if (dmx.useHistory) {
            window.history.pushState(null, null, window.location.pathname + '?' + this.buildQuery(data));
        } else {
            window.location.hash = window.location.hash.substr(1).replace(/(\?.*)?$/, '?' + this.buildQuery(data));
        }

        this.set('data', data);
    },

    buildQuery: function(query) {
        return Object.keys(query).reduce(function(qs, key) {
            if (qs) qs += '&';
            qs += encodeURIComponent(key) + '=' + encodeURIComponent(query[key]);
            return qs;
        }, '');
    }

});
